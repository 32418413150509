// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LiqpayConfigurator_container__1NQzV {\n  display: flex;\n  grid-gap: 10px;\n  gap: 10px;\n  max-height: 30vh;\n}\n.LiqpayConfigurator_container__1NQzV .LiqpayConfigurator_kitchens__18tpl {\n  overflow: auto;\n}\n.LiqpayConfigurator_container__1NQzV .LiqpayConfigurator_kitchens__18tpl, .LiqpayConfigurator_container__1NQzV .LiqpayConfigurator_kitchens__18tpl ul {\n  border-radius: 8px 0 0 8px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/pages/Settings/Payment/LiqpayConfigurator/LiqpayConfigurator.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EAAA,SAAA;EACA,gBAAA;AACF;AACE;EAQE,cAAA;AANJ;AAAI;EAEE,0BAAA;EACA,eAAA;AACN","sourcesContent":[".container {\n  display: flex;\n  gap: 10px;\n  max-height: 30vh;\n\n  & .kitchens {\n\n    &,\n    & ul {\n      border-radius: 8px 0 0 8px;\n      cursor: pointer;\n    }\n\n    overflow: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LiqpayConfigurator_container__1NQzV",
	"kitchens": "LiqpayConfigurator_kitchens__18tpl"
};
export default ___CSS_LOADER_EXPORT___;
